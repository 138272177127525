<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="confirm"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="isDetailDialog"
                :formItems="showPopForms">
                </m-dialog-content>
            </a-modal>
        <m-form
            :formItems="formItems"
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
             :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @cancelWithdraw="cancelWithdraw($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { isValid, consumerWithrawDetail, consumerWithdrawList, consumerWithdrawStart, consumerWithdrawSuccessed, consumerWithdrawCancel } from '../../utils/api'
import { assginDataToArray, formatTime, geneIndex, getValueFromObjArr } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '提现详情', eventName: 'detail'},
                 {text: '取消提现', eventName: 'cancelWithdraw'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '订单编号', dataIndex: 'orderId', key: 'orderId'},
                 {title: '收款人', dataIndex: 'name', key: 'name'},
                //  {title: '银行卡号', dataIndex: 'bankCardNo', key: 'bankCardNo'},
                 {title: '提现方式', dataIndex: 'typeText', key: 'typeText'},
                //  {title: '收款人银行', dataIndex: 'bankName', key: 'bankName'},
                //  {title: '收款人支行', dataIndex: 'bankSubName', key: 'bankSubName'},
                 {title: '金额（元）', dataIndex: 'amount', key: 'amount'},
                 {title: '提现状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '申请时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '用户ID', value: undefined},
                 {type: 'selector', label: '状态', key: 'value', options: [{label: '待操作', value: 0}, {label: '处理中', value: 1}, {label: '提现成功', value: 10}, {label: '取消提现', value: 99}], value: undefined},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
             ],
             cancelPopForms: [
                 {type: 'input', label: '备注', value: undefined}
             ],
             popForms: [
                 {label: '彩民ID', prop: 'consumerId', value: '', type: 'input'},
                 {label: '收款人', prop: 'name', value: '', type: 'input'},
                 {label: '提现金额（元）', prop: 'amount', value: '', type: 'input'},
                 {label: '提现手续费（元）', prop: 'fee', value: '', type: 'input'},
                 {label: '提现到手金额（元）', prop: 'afterFeeAmount', value: '', type: 'input'},
                 {label: '收款人银行卡号', prop: 'bankCardNo', value: '', type: 'input'},
                 {label: '收款人银行名称', prop: 'bankName', value: '', type: 'input'},
                 {label: '收款人支行名称', prop: 'bankSubName', value: '', type: 'input'},
                 {label: '申请时间', prop: 'createTime', value: '', type: 'input'},
                 {label: '修改时间', prop: 'updateTime', value: '', type: 'input'},
                 {label: '提现方式', prop: 'typeText', value: '', type: 'input'},
                 {label: '到账时间', prop: 'payTime', value: '', type: 'input'},
                 {label: '支付商订单编号', prop: 'tranOrderId', value: '', type: 'input'},
                 {label: '支付商订单备注', prop: 'tranRemark', value: '', type: 'input'},
                 {label: '提现手机', prop: 'phone', value: '', type: 'input'},
                 {label: '审核备注', prop: 'remark', value: '', type: 'input'},
                 {label: '状态', value: '', prop: 'status', type: 'radio', options: [ 
                    {label: '待处理', value: 0},
                    {label: '提现成功', value: 10}],}
            ],
             isDetailDialog: false,
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             orderId: '',
        })
        const showPopForms = computed(() => {
            return state.isDetailDialog ? state.popForms : state.cancelPopForms
        })

        const typeList = [
            ['auto', '实时到账'],
            ['manual', '人工转账'],
            ['alipay', '支付宝'],
        ]
        function initStatusText(data) {
            data.forEach(ele => {
                const s = ele.status 
                ele.statusText = s == 0 ? '待操作'
                                :s == 1 ? '处理中'
                                :s == 10 ? '提现成功'
                                :s == 99 ? '取消提现' : '未知状态'
            })
            return data
        }
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const item = state.formItems
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    consumerId: item[0].value,
                    status: item[1].value
                }
                const result = await consumerWithdrawList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                    state.tableList = initStatusText(state.tableList)
                    state.tableList.forEach(ele => {
                        ele.typeText = getValueFromObjArr(typeList, ele.type)
                        ele.createTime = formatTime(ele.createTime)
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        async function detail(event) {
            try {
                state.isDetailDialog = true
                state.title = '详情'
                state.orderId = event.orderId
                state.status = event.status
                
                const result = await consumerWithrawDetail(event.orderId)
                if (isValid(result)) {
                    console.log(result.data)
                    result.data.createTime = formatTime(result.data.createTime)
                    result.data.updateTime = formatTime(result.data.updateTime)
                    result.data.payTime = formatTime(result.data.payTime)
                    result.data.typeText = getValueFromObjArr(typeList, result.data.type)
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    console.log(state.popForms)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
        function closeDialog() {
            loadData()
            state.showDialog = false
        }
        async function confirm() {
            try {
                const status = state.popForms[10].value
                const remark = state.cancelPopForms[0].value
                if (status == 10 || !state.isDetailDialog) {
                   const result = state.isDetailDialog ?
                                  await consumerWithdrawSuccessed(state.orderId)
                                : await consumerWithdrawCancel({orderId: state.orderId, remark})
                   if (isValid(result)) {
                       const tip = state.isDetailDialog ? '处理成功' : '取消成功'
                        message.success(tip)
                        loadData()
                        state.showDialog = false
                    }
                } else {
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
        function cancelWithdraw(event) {
            state.isDetailDialog = false 
            state.title = '取消提现'
            state.orderId = event.orderId
            state.cancelPopForms[0].value = event.remark
            state.showDialog = true
            console.log(event)
        }
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            confirm,
            cancelWithdraw,
            showPopForms
        }
    },
}
</script>
<style lang='scss' scoped>
</style>